<template>
  <div>
    <a-table
      :dataSource="table_data"
      :columns="table_columns"
      :pagination="pagination_props"
      @change="changeCurrentPage"
      :loading="loading"
    >
      <template #idRender="{ text: id }">
        <span>#{{ id }}</span>
      </template>

      <template #rutRender="{ text: rut }">
        <span v-for="(r, index) in rut" :key="r"
          >{{ rutHelper.formatRut(r)
          }}{{ rut.length - 1 != index ? ', ' : '' }}</span
        >
      </template>

      <template #stateRender="{ text }">
        <a-tag :color="collectionBillingStateColor(text)">
          {{ getTagText(text)?.toUpperCase() }}
        </a-tag>
      </template>

      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-input
            v-model:value="filter_values[column.key]"
            :placeholder="`Buscar por ${column.title}`"
            :maxlength="25"
            @pressEnter="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
            style="width: 188px; margin-bottom: 8px; display: block"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <template #filterRut="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-form
            name="form"
            layout="vertical"
            ref="formRef"
            :model="filter_values"
            :rules="rules"
          >
            <a-form-item :name="column.dataIndex">
              <a-input
                v-model:value="filter_values[column.key]"
                @keyup="formatRuts"
                :placeholder="`Buscar por ${column.title}`"
                :maxlength="25"
                @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                "
                style="width: 188px; margin-bottom: 8px; display: block"
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                "
              >
                <template #icon><search-outlined /></template>
                Buscar
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="handleColumnReset(column.dataIndex, clearFilters)"
              >
                Limpiar
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </template>

      <template #filterStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="filter_values[column.key]">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in states_list[column.key]"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key].join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterPeriod="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-month-picker
            v-model:value="filter_values[column.key]"
            placeholder="Seleccionar periodo"
          />

          <div
            class="p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterCompanies="{ column, confirm, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-select
            v-model:value="selected_companies"
            :options="company_list"
            mode="multiple"
            show-search
            :filterOption="true"
            optionFilterProp="label"
            placeholder="Buscar por Razon social"
            style="width: 100%"
            size="middle"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <div
                style="
                  border-top: 1px solid #f0f0f0;
                  display: flex;
                  justify-content: space-between;
                "
                class="p-2"
                @mousedown="(e) => e.preventDefault()"
              >
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      selected_companies.join(','),
                      confirm
                    )
                  "
                >
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </div>
            </template>
          </a-select>
          <div
            class="p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  selected_companies.join(','),
                  confirm
                )
              "
            >
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterIconState="{ column }">
        <filter-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #actionDetails="{ text }">
        <a-tooltip color="#05045D">
          <template #title>Ver más</template>
          <router-link
            :to="{ name: 'detalleTicketCobranza', params: { id: text } }"
          >
            <eye-outlined />
          </router-link>
        </a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment';
import {
  SearchOutlined,
  FilterOutlined,
  EyeOutlined
} from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { watchEffect, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import notification from '../../utils/notifications';
import {
  FETCH_COLLECTION_TICKETS,
  GET_COLLECTION_TICKETS,
  GET_TOTAL_PAGES_TABLE,
  GET_ITEMS_PER_PAGE_TABLE,
  GET_CURRENT_PAGE_TABLE,
  GET_ALL_COMPANIES,
  FETCH_ALL_COMPANIES
} from '../../store/types';
import rutHelper from '../../utils/Ruts';
import getTagText from '@/utils/getTagText';
import collectionBillingStateColor from '@/utils/collectionBillingStateColor';

export default {
  name: 'CollectionBillingTickets',
  components: {
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    'eye-outlined': EyeOutlined,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const formRef = ref();

    const pagination_props = computed(() => {
      return {
        total: store.getters[GET_TOTAL_PAGES_TABLE],
        pageSize: store.getters[GET_ITEMS_PER_PAGE_TABLE],
        current: store.getters[GET_CURRENT_PAGE_TABLE]
      };
    });

    const table_data = computed(() => {
      const data = store.getters[GET_COLLECTION_TICKETS].map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          collectionState: obj.stateCollection || '-',
          invoiceState: obj.stateInvoice || '-',
          holdingName: obj?.holding || '-',
          subHoldingName: obj?.subHoldings.join(', ') || '-',
          companyRut: obj?.rutCompanies || '-',
          companyId: obj?.companies?.join(', ') || '-',
          numberPolicy: obj?.policies?.join(', ') || '-',
          period: obj.period,
          action: obj.id
        };
      });

      return data;
    });

    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender'
        }
      },
      {
        title: 'Estado cobranza',
        dataIndex: 'collectionState',
        key: 'collectionState',
        slots: {
          customRender: 'stateRender',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Estado facturas',
        dataIndex: 'invoiceState',
        key: 'invoiceState',
        slots: {
          customRender: 'stateRender',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Holding',
        dataIndex: 'holdingName',
        key: 'holdingName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHoldingName',
        key: 'subHoldingName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT Empresa',
        dataIndex: 'companyRut',
        key: 'companyRut',
        slots: {
          customRender: 'rutRender',
          filterDropdown: 'filterRut',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Póliza',
        dataIndex: 'numberPolicy',
        key: 'numberPolicy',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Periodo',
        dataIndex: 'period',
        key: 'period',
        slots: {
          filterDropdown: 'filterPeriod',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        slots: {
          customRender: 'actionDetails'
        }
      }
    ];

    const states_list = {
      collectionState: [
        {
          label: 'Iniciada',
          value: 'CREATED'
        },
        {
          label: 'Validación corredora',
          value: 'BEWELL_VALIDATION'
        },
        {
          label: 'Validación RRHH',
          value: 'HR_VALIDATION'
        },
        {
          label: 'Aprobado',
          value: 'APPROVED'
        }
      ],
      invoiceState: [
        {
          label: 'Emitida',
          value: 'ISSUED'
        },
        {
          label: 'Sin Emitir',
          value: 'NOT_ISSUED'
        },
        {
          label: 'Pagada',
          value: 'PAID'
        }
      ]
    };

    const checkRut = async (rule, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };

    const rules = {
      companyRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const formatRuts = () => {
      if (filter_values.value['companyRut']) {
        filter_values.value['companyRut'] = rutHelper.formatRut(
          filter_values.value['companyRut']
        );
      }
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    const handleSearch = (column, value, confirm) => {
      if (value != '' && value != undefined) {
        formRef.value.validate().then(() => {
          let search_value = value;

          if (column === 'period') {
            search_value = value.format('YYYY-MM');
          }

          if (column === 'companyRut') {
            search_value = rutHelper.normalizeRut(value);
          }

          filterRedirectionNewParam(column, search_value);
          confirm();
        });
      } else {
        formRef.value.clearValidate();
      }
    };

    const filterRedirectionResetColumn = (key) => {
      delete search_value.value[key];
      delete filter_values.value[key];

      router.replace({
        name: 'CobranzaFacturacion',
        query: search_value.value,
        hash: '#all'
      });
    };

    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();

      if (column === 'companyId') {
        selected_companies.value = [];
      }

      delete filter_values.value[column];
    };

    // * Redireccion
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros
    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    const filterRedirectionNewParam = (key, param) => {
      search_value.value[key] = param;

      if (key != 'page') {
        search_value.value['page'] = 1;
      }

      router.replace({
        name: 'CobranzaFacturacion',
        query: search_value.value,
        hash: '#all'
      });
    };

    // * Lista de compañias
    const selected_companies = ref([]);
    const company_list = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });
    const getAllCompanies = () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // * Obtencion de data
    const getCollectionBillingTickets = async (params) => {
      loading.value = true;
      try {
        await store.dispatch(FETCH_COLLECTION_TICKETS, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    watchEffect(() => {
      if (
        route.name === 'CobranzaFacturacion' &&
        (!route.hash || route.hash === '#all')
      ) {
        const params = route.query;

        if ('page' in params === false) {
          params['page'] = 1;
        }
        const string_param = new URLSearchParams(params).toString();
        getCollectionBillingTickets(string_param);
      }
    });

    onMounted(() => {
      const keys = Object.keys(route.query);

      keys.forEach((element) => {
        search_value.value[element] = route.query[element];
        if (element === 'period') {
          filter_values.value[element] = moment(
            route.query[element],
            'YYYY-MM'
          );
        } else if (
          element === 'collectionState' ||
          element === 'invoiceState'
        ) {
          filter_values.value[element] = [...route.query[element].split(',')];
        } else if (element === 'companyId') {
          filter_values.value[element] = [...route.query[element].split(',')];
          selected_companies.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        } else {
          filter_values.value[element] = route.query[element];
        }
      });

      getAllCompanies();
    });

    return {
      filter_values,
      search_value,
      table_columns,
      table_data,
      pagination_props,
      loading,
      collectionBillingStateColor,
      getTagText,
      states_list,
      rules,
      formRef,
      rutHelper,
      formatRuts,
      changeCurrentPage,
      handleSearch,
      handleColumnReset,
      selected_companies,
      company_list
    };
  }
};
</script>

<style scoped>
.request_icon {
  color: #2521e5;
}
</style>
